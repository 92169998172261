<template lang="pug">
  div
    slot(name="activator")
      v-btn(
        small
        color="primary"
        @click="open"
        :disabled="disabled"
        outlined
      ).top-fields__item {{ canReapply ? 'Cancel manual reapplication' : 'Allow reapplication' }}

    app-dialog(v-model="showing" width="400px" title="Access to reapplication")
      template(#dialog-body)
        .body-content
          app-datepicker(
            :value="datetime"
            @input="datetime = $event"
            hideDetails
            no-title
            :min="getMinDate"
            :max="getMaxDate"
          )
            template(v-slot:activator="{showDatepicker, formattedDate, on}")
              app-input(
                :value="formattedDate"
                label="Reapplication is available until"
                :iconLink="'ui/calendar.svg'"
                placeholder="Select dates in calendar"
                readonly
                v-on="on"
                @click="showDatepicker"
              )

      template(#dialog-footer)
        app-button(color="#f4f7f9" @click.native="toggle" hide-shadow).mr-3
          span.text-label Close
        app-button(color="primary" @click.native="change") Save
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import appDialog from '@/components/global/actions/BaseDialog.vue'
import appDatepicker from '@/app/admin/components/CrmDatepicker.vue'
import appInput from '@/components/global/actions/BaseInput'
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'CandidatesReapply',

  inject: ['svc'],

  mixins: [showingMixin, errorsMixin],

  components: {
    appDialog,
    appDatepicker,
    appInput,
    appButton
  },

  props: {
    ID: Number,
    reapplyDateTime: [String, null],
    disabled: Boolean,
  },

  data: () => ({
    loading: false,
    datetime: null
  }),

  computed: {
    canReapply() {
      let _now = this.$dates(new Date()).format('YYYY-MM-DD')
      let _datetime = this.$dates(this.reapplyDateTime).format('YYYY-MM-DD')
      return this.reapplyDateTime !== null && (_now < _datetime);
    },

    getMinDate() {
      return this.$dates(new Date()).add(1, 'day').format('YYYY-MM-DD')
    },

    getMaxDate() {
      return this.$dates(new Date()).add(30, 'day').format('YYYY-MM-DD')
    }
  },

  methods: {
    async change() {
      if (this.loading) return true

      let _dateTime
      if (this.datetime) _dateTime = this.$dates(this.datetime).format('YYYY-MM-DD')
      else _dateTime = null

      try {
        this.loading = true
        await this.svc().changeReapplyState(this.ID, _dateTime)

        if (this.datetime) this.$notify({text: 'Reapplication is allowed', type: 'success'})
        else this.$notify({text: 'Reapplication is cancelled', type: 'success'})
        this.updateShowing(false)

      } catch (err) {
        this.processError(err)
      } finally {
        this.loading = false
      }
    },

    async open() {
      if (this.canReapply) {
        this.datetime = null
        await this.change()
      }
      else this.toggle()
    },

    clear() {
      this.datetime = null
    },

    prefill() {
      if (this.reapplyDateTime) this.datetime = this.reapplyDateTime
    }
  },

  watch: {
    showing(value) {
      if (!value) this.clear()
      else this.prefill()
    }
  },
}
</script>

<style lang="scss"></style>
